import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PortableText from '@sanity/block-content-to-react';
import { compareAsc, format } from 'date-fns';
import CoverImage from '../components/covers/CoverImage';
import Grid from '../components/Grid';
import Newsletter from '../components/blocks/Newsletter';
import YouTubeEmbed from '../components/YoutubeEmbed';
import PageTransition from '../components/PageTransition';

export default function SingleEvent({ data }) {
  const { event } = data;

  const ArtistTextHolder = styled.div`
    grid-column: 3 / span 8;
    main {
      grid-column: span 12;
    }
  `;

  console.log(data.event.date);

  // const stringToDisplay = format(new Date(data.event.date), 'HH:mm'); // '23:05'
  // const stringToDisplay = format(new Date(data.event.date), 'yyyy-MM-dd');
  // console.log(stringToDisplay, 'tfds');

  return (
    <PageTransition>
      <div className="container.no-margin-bottom">
        <title>{event.name} | Rizoom.net</title>
        <Grid>
          <CoverImage
            title={event.name}
            text={event.eventIntro}
            image={event.image}
            ticketLink={event.href}
            ticketButtonText={event.buyLinkText}
            type="event"
            startDate={event.startDate}
            place={event.place}
            price={event.price}
          />
          <div style={{ textAlign: 'center' }} />
        </Grid>
        <Grid>
          <ArtistTextHolder>
            <div className="block no-padding-top">
              <main>
                {event?._rawRichText?.map((block, i) => (
                  <React.Fragment key={i}>
                    <>
                      {block._type === 'youtube' ? (
                        <YouTubeEmbed youtube={block.url} />
                      ) : null}
                      {block._type === 'block' ? (
                        <PortableText blocks={block} />
                      ) : null}
                    </>
                  </React.Fragment>
                ))}
              </main>
            </div>
          </ArtistTextHolder>
        </Grid>
        <Grid>
          <Newsletter title="Blijf up to date met Rizoom makers, verhalen en optredens" />
        </Grid>
      </div>
    </PageTransition>
  );
}

export const query = graphql`
  query($slug: String!) {
    event: sanityEvent(slug: { current: { eq: $slug } }) {
      _id
      name
      location
      place
      startDate
      endDate
      _rawRichText
      eventIntro
      href
      price
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 2100) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;

import React from 'react';
import getYouTubeID from 'get-youtube-id';

import styled from 'styled-components';

export default function YouTubeEmbed(props) {
  const id = getYouTubeID(props.youtube);
  const url = `https://www.youtube.com/embed/${id}`;

  if (!id) {
    return <div>Missing URL</div>;
  }
  const VideoContainerStyles = styled.div`
    iframe {
      @media screen and (max-width: 39.9375em) {
        height: 220px;
      }
      height: 320px;
    }
  `;
  return (
    <VideoContainerStyles>
      <iframe
        width="100%"
        height="100%"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoContainerStyles>
  );
}
